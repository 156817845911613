import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import Hero from "../components/page/home/Hero"
import { createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import Features from "../components/page/home/Features"
import { canSync, scrollTo } from "../var/util"
import Pricing from "../components/page/home/Pricing"
import Footer from "../components/partials/Footer"
import Meta from "../components/Meta"
import Promo from "../components/page/home/Promo"

const useStyles = makeStyles((t) => createStyles({
  main: {},
  scroll: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    width: "100%",
    height: "100%",

    // transition: "all 100ms linear 0s",
    // background: `radial-gradient(circle at 10% 20%, ${theme.palette.primary.dark} 0%,  ${theme.palette.primary.main} 100.2%)`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
    background: theme.custom.fancyBackground,
    transform: "skewY(-4deg)",
    transformOrigin: "top left",
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const IndexPage = () => {

  const classes = useStyles()
  return (
    <React.Fragment>
      <Meta title={""}/>
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Memo Reminders"/>
            {/*<div className={classes.background}/>*/}
            <Hero/>
            <Promo />
            <Features/>
            {canSync() &&
            <Pricing/>
            }
            <Footer excludeSupport={!canSync()}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </NoSsr>
    </React.Fragment>
  )
}

export default IndexPage
