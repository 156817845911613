import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, createStyles, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Download from "../../partials/Download"
import { graphql, Link, useStaticQuery } from "gatsby"
import ReactCompareImage from "react-compare-image"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    // minHeight: "100vh",
    color: theme.palette.primary.contrastText,
    position: "relative",
    // [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(4),
    // },
  },
  content: {
    position: "relative",
  },
  toolbar: theme.mixins.toolbar,
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    width: "100%",
    height: "100%",

    // transition: "all 100ms linear 0s",
    // background: `radial-gradient(circle at 10% 20%, ${theme.palette.primary.dark} 0%,  ${theme.palette.primary.main} 100.2%)`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
    background: theme.custom.fancyBackground,
    transform: "skewY(-4deg)",
    transformOrigin: "top left",
  },
  backgroundBottom: {
    position: "absolute",
    left: 0,
    bottom: -6,
    width: "100vw",
  },
  heroImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 8,
    // transition: "display 1s ease-in-out",
    pointerEvents: "none",
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: "underline",
    fontSize: 16,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  darkMode: {
    textDecoration: "underline",
    marginTop: -35,
  },
}))


const Hero = ({ title }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
     light: file(relativePath: {eq: "mr_light.png"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    dark: file(relativePath: {eq: "mr_dark.png"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`)

  return (
    <section id={"hero"} className={classes.main}>
      <div className={classes.background}/>
      <div className={classes.toolbar}/>

      <Container maxWidth={"lg"} className={classes.content}>
        <Grid container spacing={4} justify={"center"} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4} justify={"center"} alignItems={"center"}>
              <Grid item xs={12} sm={6} md={12}>
                <Typography variant={"h3"}>Memo Reminders</Typography>
                <Typography variant={"body1"} gutterBottom>
                  Often forgetting to do or get something? You need quick memo or reminder without the hustle? Try "Memo
                  reminders".
                </Typography>
                <Typography variant={"h4"}>
                  How does it work?
                </Typography>
                <Typography variant={"body1"}>
                  On your mobile device, "Memo reminders" creates reminders as notifications which you can see them in
                  the notification
                  drawer.
                </Typography>
                <Typography variant={"body1"}>
                  In your browser, you can see your reminders each time you open a new tab.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                  Easily accessible, always there. <Link to={"/about"} className={classes.link}>Learn more</Link>.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Download/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReactCompareImage
              className={classes.heroImage}
              leftImage={data.dark.childImageSharp.fluid.src}
              rightImage={data.light.childImageSharp.fluid.src}
              handle={<Fragment/>}
              hover={true}
              sliderLineWidth={0}
            />
            <Typography
              align={"center"}
              variant={"body1"}
              className={classes.darkMode}
            >Yes, there is dark mode
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default Hero
