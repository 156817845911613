import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles, Paper } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PlayButton from "../buttons/Play"
import ChromeButton from "../buttons/Chrome"
import Typography from "@material-ui/core/Typography"
import RestButton from "../buttons/Rest"
import IOSButton from "../buttons/IOS"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    background: theme.palette.primary.main,
    '&:hover': {
      boxShadow: theme.shadows[8]
    },
    color: theme.palette.primary.contrastText
    // borderRadius:8,
    // borderStyle: 'dashed',
    // borderColor: theme.palette.primary.main,
  },
}))

const Download = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <Paper className={classes.main} id={"download"}>
        <Grid container alignItems={"flex-start"} justify={"center"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant={"subtitle1"} align={"center"}>For your browser:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChromeButton/>
          </Grid>
          <Grid item xs={12} md={6}>
            <RestButton/>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.main}>
        <Grid container alignItems={"flex-start"} justify={"center"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant={"subtitle1"} align={"center"}>For your mobile device:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <PlayButton/>
          </Grid>
          <Grid item xs={12} md={6}>
            <IOSButton/>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  )
}

export default Download
