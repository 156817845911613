import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Container, createStyles, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import { scrollTo } from "../../../var/util"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import CheckIcon from "@material-ui/icons/CheckRounded"
import UncheckIcon from "@material-ui/icons/ClearRounded"
import positiveColor from "@material-ui/core/colors/green"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    position: "relative",
  },
  gridItem: {
    flex: "1 0",
    minWidth: 280,
    maxWidth: 460,
  },
  item: {
    // color: theme.palette.primary.contrastText,
    // background: theme.palette.primary.main,
    minHeight: theme.spacing(30),
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    // fontSize: 14,
    fontWeight: "bold",
  },
  pos: {
    marginBottom: 12,
  },
  cardHeader: {
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    padding: theme.spacing(2),
    background: theme.custom.fancyBackground,
    minHeight: 210,
  },
  cardContent: {
    flex: 1,
  },
  selectedPrice: {
    margin: `0 ${theme.spacing(1) / 2}px`,
    // fontSize:'0.75rem',
    // textTransform: "lowercase",
    textDecoration: "underline",
  },
  price: {
    margin: `0 ${theme.spacing(1) / 2}px`,
    // fontSize:'0.75rem',
    // textTransform: "lowercase",
  },
  positiveIcon: {
    color: positiveColor[500],
  },
}))


const Pricing  = () => {

  const classes = useStyles()

  const prices = [
    {
      title: "monthly",
      label: "monthly",
      price: "$2",
      total: "",
      separator: "/",
    },
    {
      title: "semiannual",
      label: "twice per year",
      price: "$1.50",
      total: "$9",
      separator: "/",
    },
    {
      title: "annual",
      label: "yearly",
      price: "$1",
      total: "$12",
      separator: "",
    },
  ]

  const [currentPrice, setPrice] = useState(prices[1])

  const handleClick = () => {
    scrollTo("#download")
  }
  const plans = [
    {
      id: "#free",
      elevation: 4,
      caption: "Free",
      title: `Free`,
      subtitle: "forever",
      features: [
        {
          title: "Browser extension",
          checked: true,
        },
        {
          title: "Android application",
          checked: true,
        },
        {
          title: "Pro features for 14 days, no credit card required",
          checked: true,
        },
        {
          title: "Sync between devices",
          checked: false,
        },
      ],
      description: "Fully featured browser extension and Android application. See and add reminders right from your new tab page. Try pro features for 14 days, no credit card required.",
    },
  ]

  const features = [
    {
      title: "Browser extension",
      checked: true,
    },
    {
      title: "Android application",
      checked: true,
    },
    {
      title: "Sync reminders between devices",
      checked: true,
    },
    {
      title: "Sync extension settings and widgets",
      checked: true,
    },
    {
      title: "Priority email support",
      checked: true,
    },
  ]
  return (
    <section id={"pricing"} className={classes.main}>
      <Container maxWidth={"lg"}>
        <Grid container spacing={4} justify={"center"}>
          {plans.map(plan =>
            <Grid key={plan.id} item xs={12} sm={6} className={classes.gridItem}>
              <Card className={classes.item} variant="elevation" elevation={plan.elevation}>
                <Box className={classes.cardHeader}>
                  <Typography className={classes.title} color={"inherit"} gutterBottom variant={"h4"} align={"center"}>
                    {plan.caption}
                  </Typography>
                  <Typography variant="h3" color={"inherit"}>
                    {plan.title}
                  </Typography>
                  <Typography variant="h6" color={"inherit"}>
                    {plan.subtitle}
                  </Typography>
                </Box>
                <CardContent className={classes.cardContent}>
                  <List>
                    {plan.features.map((feature, key) =>
                      <ListItem key={key}>
                        <ListItemText>
                          {feature.title}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          {feature.checked &&
                          <CheckIcon className={classes.positiveIcon}/>
                          }
                          {!feature.checked &&
                          <UncheckIcon color={"error"}/>
                          }
                        </ListItemSecondaryAction>
                      </ListItem>)}
                  </List>
                </CardContent>
                <CardActions>
                  <Button variant={"contained"} fullWidth color={"primary"} onClick={handleClick}>Get started</Button>
                </CardActions>
              </Card>
            </Grid>,
          )}
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <Card className={classes.item} variant="elevation" elevation={4}>
              <Box className={classes.cardHeader}>
                <Typography color={"inherit"} className={classes.title} gutterBottom variant={"h4"} align={"center"}>
                  Pro
                </Typography>
                <Typography variant="h3">
                  {currentPrice.price}/<Typography variant={"h5"} component={"span"}>month</Typography>
                </Typography>
                <Typography variant="h6">
                  Billed <strong>{currentPrice.total}</strong> {currentPrice.label}
                </Typography>
                <Box>
                  <Grid container justify={'center'} spacing={0} alignItems={'flex-start'}>
                    <Grid item xs={12}>
                      <Typography variant={'caption'} color={'inherit'}>Contract:</Typography>
                    </Grid>
                    {prices.map((price, key) =>
                      <Grid item key={key} xs={4}>
                        <Button fullWidth
                                className={currentPrice.title === price.title ? classes.selectedPrice : classes.price}
                                onClick={() => {
                                  setPrice(price);
                                }}
                                size={'small'}
                                color={'inherit'}
                                variant={'text'}>{price.title}</Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>

              </Box>
              <CardContent className={classes.cardContent}>
                <List>
                  {features.map((feature, key) =>
                    <ListItem key={key}>
                      <ListItemText>
                        {feature.title}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        {feature.checked &&
                        <CheckIcon className={classes.positiveIcon}/>
                        }
                        {!feature.checked &&
                        <UncheckIcon color={"error"}/>
                        }
                      </ListItemSecondaryAction>
                    </ListItem>)}
                </List>
              </CardContent>
              <CardActions>
                <Button variant={"contained"} fullWidth color={"primary"} onClick={handleClick}>Get started</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default Pricing
