import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => createStyles({
  image: {
    padding: `0 ${theme.spacing(1) / 2}px`,
  },
}))

const RestButton  = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
     firefox: file(relativePath: {eq: "buttons/firefox.png"}) {
       childImageSharp {
        fixed(height: 55, grayscale: false) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    safari: file(relativePath: {eq: "buttons/safari.png"}) {
       childImageSharp {
        fixed(height: 55, grayscale: false) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    opera: file(relativePath: {eq: "buttons/opera.png"}) {
       childImageSharp {
        fixed(height: 55, grayscale: false) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`)
  return (
    <Grid container justify={"center"} spacing={0}>
      <Grid item className={classes.image}>
        <Img fixed={data.firefox.childImageSharp.fixed} loading={"eager"} title={"Coming to Firefox Add-ons"}/>
      </Grid>
      <Grid item className={classes.image}>
        <Img fixed={data.safari.childImageSharp.fixed} loading={"eager"} title={"Coming to Safari Extensions"}/>
      </Grid>
      <Grid item className={classes.image}>
        <Img fixed={data.opera.childImageSharp.fixed} loading={"eager"} title={"Coming to Opera Add-ons"}/>
      </Grid>
      <Grid item xs={12}>
        <Typography>Coming soon</Typography>
      </Grid>
    </Grid>
  )
}

export default RestButton
