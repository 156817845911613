import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Img from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import { URLS } from "../../var/const"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    marginTop: 12,
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const IOSButton  = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
     file(relativePath: {eq: "buttons/ios.png"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`)
  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
      <Link to={URLS.IOS_SUBSCRIBE}
            className={classes.button}>
        <Img fixed={data.file.childImageSharp.fixed} loading={"eager"} title={"Get notified for the iOS version"}/>
      </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={URLS.IOS_SUBSCRIBE} className={classes.link}
        >Get notified for the iOS version</Link>
      </Grid>

    </Grid>
  )
}

export default IOSButton
