import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, createStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import FastIcon from "@material-ui/icons/SpeedRounded"
import EasyIcon from "@material-ui/icons/StarRounded"
import PowerfulIcon from "@material-ui/icons/ImportantDevicesRounded"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    position: "relative",
    // top: -theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      // top: -theme.spacing(20),
    },
  },
  background: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // bottom: 0,
    // top: 0,
    // width: "100%",
    // height: "100%",
    // transition: "all 100ms linear 0s",
    // background: `radial-gradient(circle at 10% 20%, ${theme.palette.primary.dark} 0%,  ${theme.palette.primary.main} 100.2%) center center / cover no-repeat`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
  },
  item: {
    // height: theme.spacing(30),
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
    // cursor: "pointer",
    height:'100%'
    // cursor: "pointer",
  },
  gridItem:{
    flex: '1 0 auto'
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  feature: {},
  featureImage: {
    maxWidth: "100%",
    borderRadius: 10,
    paddingBottom: theme.spacing(2),
  },
  cardRoot: {
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  link: {
    color: "inherit",
  },
}))


const Features = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
     fast: file(relativePath: {eq: "fast.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    easy: file(relativePath: {eq: "easy.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    powerful: file(relativePath: {eq: "powerful.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`)
  const features = [
    {
      id: "#fast",
      caption: <FastIcon fontSize={"small"}/>,
      title: `Fast`,
      subtitle: "add reminders",
      description: "Add reminders right from new tab page or as soon as you open the application.",
      img: data.fast.childImageSharp.fluid,
    },
    {
      id: "#easy",
      caption: <EasyIcon fontSize={"small"}/>,
      title: `Easy`,
      subtitle: "easily accessible, always there",
      description: "See your reminders each time you open new tab or pull down notifications on your mobile phone.",
      img: data.easy.childImageSharp.fluid,
    },
    {
      id: "#powerful",
      caption: <PowerfulIcon fontSize={"small"}/>,
      title: `Powerful`,
      subtitle: "quick actions",
      description: <Fragment>
        Add actions to your reminders. Quickly open website, navigate, make a call and <Link
        className={classes.link} to={"/how-to"}>more...</Link>
      </Fragment>,
      img: data.powerful.childImageSharp.fluid,
    },

  ]
  return (
    <section id={"features"} className={classes.main}>
      <div className={classes.background}/>
      <Container maxWidth={"lg"}>
        <Grid container spacing={4} justify={"center"}>
          {features.map(feature =>
            <Grid className={classes.gridItem} key={feature.id} item xs={12} sm={6} md={4}>
              <Card className={classes.item} variant="elevation" elevation={4}>
                <CardContent>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {feature.caption}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        {feature.title}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {feature.subtitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Img fluid={feature.img}
                           imgStyle={{
                             objectFit: "contain",
                             objectPosition: "top center",
                           }}
                           loading={"eager"} title={feature.title}
                           className={classes.featureImage}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" component="p">
                        {feature.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>,
          )}
        </Grid>
        {/*<Grid container spacing={4} justify={"center"}>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Card className={classes.feature} id={"fast"}>*/}
        {/*      <CardContent classes={{ root: classes.cardRoot }}>*/}
        {/*        <Grid container spacing={2}>*/}
        {/*          <Grid item sm={6} xs={12}>*/}
        {/*            <Typography variant="h5" component="h2" gutterBottom>*/}
        {/*              Fast*/}
        {/*            </Typography>*/}
        {/*            <Typography variant={"body1"}>*/}
        {/*              Add reminders as soon as you open the application or right from new tab page.*/}
        {/*            </Typography>*/}
        {/*          </Grid>*/}
        {/*          <Grid item sm={6} xs={12}>*/}
        {/*            <Img fluid={data.fast.childImageSharp.fluid}*/}
        {/*                 imgStyle={{*/}
        {/*                   objectFit: "contain",*/}
        {/*                   objectPosition: "top center",*/}
        {/*                 }}*/}
        {/*                 loading={"eager"} title={"Fast"}*/}
        {/*                 className={classes.featureImage}/>*/}
        {/*          </Grid>*/}
        {/*        </Grid>*/}
        {/*      </CardContent>*/}
        {/*    </Card>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Card className={classes.feature} id={"easy"}>*/}
        {/*      <CardContent classes={{ root: classes.cardRoot }}>*/}
        {/*        <Grid container spacing={2}>*/}
        {/*          <Grid item sm={6} xs={12}>*/}
        {/*            <Img fluid={data.easy.childImageSharp.fluid}*/}
        {/*                 imgStyle={{*/}
        {/*                   objectFit: "contain",*/}
        {/*                   objectPosition: "top center",*/}
        {/*                 }}*/}
        {/*                 loading={"eager"} title={"Easy"}*/}
        {/*                 className={classes.featureImage}/>*/}
        {/*          </Grid>*/}
        {/*          <Grid item sm={6} xs={12}>*/}
        {/*            <Typography variant="h5" component="h2" gutterBottom>*/}
        {/*              Easy*/}
        {/*            </Typography>*/}
        {/*            <Typography variant={"body1"}>*/}
        {/*              See your reminders each time you open new tab or notifications on your mobile phone*/}
        {/*            </Typography>*/}
        {/*          </Grid>*/}
        {/*        </Grid>*/}
        {/*      </CardContent>*/}
        {/*    </Card>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Card className={classes.feature} id={"powerful"}>*/}
        {/*      <CardContent classes={{ root: classes.cardRoot }}>*/}
        {/*        <Grid container spacing={2}>*/}
        {/*          <Grid item sm={6} xs={12}>*/}
        {/*            <Typography variant="h5" component="h2">*/}
        {/*              Powerful*/}
        {/*            </Typography>*/}
        {/*            <Typography variant={"body1"}>*/}
        {/*              Add actions to your reminders. Quickly open website, navigate or make a call.*/}
        {/*            </Typography>*/}
        {/*          </Grid>*/}
        {/*          <Grid item sm={6} xs={12}>*/}
        {/*            <Img fluid={data.powerful.childImageSharp.fluid}*/}
        {/*                 imgStyle={{*/}
        {/*                   objectFit: "contain",*/}
        {/*                   objectPosition: "top center",*/}
        {/*                 }}*/}
        {/*                 loading={"eager"} title={"Powerful"}*/}
        {/*                 className={classes.featureImage}/>*/}
        {/*          </Grid>*/}
        {/*        </Grid>*/}
        {/*      </CardContent>*/}
        {/*    </Card>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Container>
    </section>
  )
}

export default Features
