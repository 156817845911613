import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, createStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import ProductHuntButton from "../../buttons/ProductHunt"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    position: "relative",
    // top: -theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      // top: -theme.spacing(20),
    },
    marginBottom: theme.spacing(2),
  },
  background: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // bottom: 0,
    // top: 0,
    // width: "100%",
    // height: "100%",
    // transition: "all 100ms linear 0s",
    // background: `radial-gradient(circle at 10% 20%, ${theme.palette.primary.dark} 0%,  ${theme.palette.primary.main} 100.2%) center center / cover no-repeat`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
  },
  item: {
    // height: theme.spacing(30),
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
    height: "100%",
    // cursor: "pointer",
  },
  gridItem: {
    flex: "1 0 auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  feature: {},
  featureImage: {
    maxWidth: "100%",
    borderRadius: 10,
    paddingBottom: theme.spacing(2),
  },
  cardRoot: {
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: "underline",
  },
}))


const Promo = () => {
  const classes = useStyles()
  const promos = [
    {
      id: "#ph",
      subtitle: "producthunt.com",
      description: <Fragment><ProductHuntButton/></Fragment>,
      align:"center",
      link: "https://www.producthunt.com/posts/memo-reminders",
    },
    {
      id: "#xda",
      subtitle: "xda-developers.com",
      description: "Memo reminders is an elegant app with material design. It’s also free and free from advertisements.",
      align:"left",
      link: "https://www.xda-developers.com/quick-notification-memos-action-shortcuts-using-memo-reminders/",
    },
    {
      id: "#androidcommunity",
      subtitle: "androidcommunity.com",
      description: "Memo Reminders is easy accessible, persistent, gets the work done.",
      align:"left",
      link: "https://androidcommunity.com/memo-reminders-is-easy-accessible-persistent-gets-the-work-done-20150911/",
    },
    {
      id: "#androidflagship",
      subtitle: "androidflagship.com",
      description: "Memo Reminders seems the ideal option when it comes to creating Notification Memos and Reminders.",
      align:"left",
      link: "https://androidflagship.com/16220-create-notification-memos-reminders-memo-reminders/",
    },
  ]
  const promoCount = promos.length
  return (
    <section id={"promo"} className={classes.main}>
      <div className={classes.background}/>
      <Container maxWidth={"lg"}>
        <Grid container spacing={4} justify={"center"}>
          {promos.map(promo =>
            <Grid className={classes.gridItem} key={promo.id} item xs={12} sm={6} md={12 / promoCount}>
              <Card className={classes.item} variant="elevation" elevation={4}>
                <CardContent>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography className={classes.pos} color="textSecondary">
                        <Link target="_blank" rel="noopener" href={promo.link} className={classes.link}
                        >{promo.subtitle}</Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align={promo.align} variant="body1" component="p">
                        {promo.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>,
          )}
        </Grid>
      </Container>
    </section>
  )
}

export default Promo
